.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: var(--content-width);
  margin: 0 auto;

  @media screen and (min-width: 279px) and (max-width: 1279px) {
    padding: 30px 15px 50px;

    & + section {
      margin-top: 45px;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1279px) {
    padding: 70px 15px 90px;

    & + section {
      margin-top: 45px;
    }
  }

  @media screen and (min-width: 1280px) {
    padding: 70px 105px 90px;

    & + section {
      margin-top: 90px;
    }
  }
}

.container {
  display: flex;
  width: 100%;
  background-color: var(--gray-light);

  @media screen and (min-width: 279px) and (max-width: 1279px) {
    & + section {
      margin-top: 45px;
    }
  }

  @media screen and (min-width: 1280px) {
    & + section {
      margin-top: 90px;
    }
  }
}

.title {
  margin-bottom: 15px;
  font-weight: bold;
  color: var(--black);

  @media screen and (min-width: 279px) and (max-width: 767px) {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }

  @media screen and (min-width: 768px) {
    font-size: 1.625rem;
    line-height: 2rem;
  }
}

.text {
  width: 100%;
  margin-bottom: 35px;
  color: var(--black);

  @media screen and (min-width: 279px) and (max-width: 767px) {
    font-size: 0.875rem;
    line-height: 1.0625rem;
  }

  @media screen and (min-width: 768px) {
    max-width: 720px;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  &Form {
    margin-top: 30px;
    white-space: pre;
  }
}

.submitButton {
  @media screen and (min-width: 1280px) {
    margin-left: auto;
    width: calc(50% - 10px);
  }
}
