.container {
  width: 100%;
  background-color: var(--gray-light-menu);

  @media screen and (max-width: 767px) {
    padding: 15px;
  }

  @media screen and (min-width: 768px) {
    align-items: center;
    justify-content: center;
    padding: 15px 25px;
  }
}

.content {
  display: flex;
  max-width: 1050px;
  margin: 0 auto;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }

  @media screen and (min-width: 768px) {
    align-items: center;
    justify-content: center;
  }
}

.text,
.title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.title {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.5625rem;

  @media screen and (max-width: 767px) {
    margin-bottom: 5px;
  }
}

.text {
  text-transform: capitalize;
  font-size: 0.875rem;
  line-height: 1.0625rem;
}

.text,
.title,
.link {
  color: var(--black);
}

.info {
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 767px) {
    margin-bottom: 30px;
  }

  @media screen and (min-width: 768px) {
    margin-right: 50px;
    max-width: 50%;
  }
}

.actions {
  display: flex;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }

  @media screen and (min-width: 768px) {
    align-items: center;
  }

  @media screen and (min-width: 768px) and (max-width: 1049px) {
    flex-wrap: wrap;
  }
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  min-height: 40px;
  font-size: 0.875rem;
  line-height: 1.0625rem;

  @media screen and (min-width: 768px) and (max-width: 1049px) {
    flex: 1;
  }

  @media screen and (min-width: 1050px) {
    margin-left: 25px;
  }
}

.button {
  padding: 0 24px;

  @media screen and (min-width: 768px) {
    min-width: 240px;
  }
}
